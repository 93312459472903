import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-export-excel-button',
    templateUrl: './export-excel-button.component.html',
    styleUrls: [`./export-excel-button.component.css`]
})
export class ExportExcelButtonComponent {
    @Input() loading = false;

    constructor() { }
}
