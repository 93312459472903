import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { DateService } from '@kayne/ka-ui-lib-ng7';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import * as _ from 'lodash';

@Component({
    selector: 'app-month-range-picker',
    templateUrl: './month-range-picker.component.html',
    styleUrls: [`./month-range-picker.component.css`]
})
export class MonthRangePickerComponent implements OnInit, OnChanges {
    loading = false;
    min;
    @Input() isStartDateDisabled = false;
    @Input() isEndDateDisabled = false;
    @Input() onlyAllowEndQuarterMonth = false;
    @Input() enableSubtractStartDate = false;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Output() change: EventEmitter<{ startDate: Date, endDate: Date, isStartDateChange: boolean, isEndDateChange: boolean }> = new EventEmitter();

    @ViewChild('startDatePicker') startDatePicker: Element;
    @ViewChild('endDatePicker') endDatePicker: Element;
    currentOpenedCalendar;
    constructor(private dateService: DateService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            this.min = !this.enableSubtractStartDate ? this.startDate : null;
        }
    }

    ngOnInit() {
    }

    onStartDateChange(event) {
        if (this.onlyAllowEndQuarterMonth) {
            event.value = event.value ? this.dateService.initDate(event.value).endOf('quarter').toDate() : null;
        }
        this.change.emit({ startDate: event.value, endDate: this.endDate, isStartDateChange: true, isEndDateChange: false });
    }

    onEndDateChange(event) {
        if (this.onlyAllowEndQuarterMonth) {
            event.value = event.value ? this.dateService.initDate(event.value).endOf('quarter').toDate() : null;
            this.startDate = this.enableSubtractStartDate ? this.valuationFilterUpdate(event.value) : event.value;
            this.min = this.startDate;
        }
        this.change.emit({ startDate: this.startDate, endDate: event.value, isStartDateChange: false, isEndDateChange: true });
    }


    valuationFilterUpdate(endDate) {
        let startDate;
        if (endDate) {
            const convertedEndDate = this.dateService.formatDateLocal(endDate, 'YYYY-MM-DD');
            startDate = this.dateService.initDate(convertedEndDate).date(1).subtract(1, 'quarter').endOf('quarter').toDate();
        }
        return startDate;
    }


    onOpen(args: any) {
        if (args && this.onlyAllowEndQuarterMonth) {
            this.currentOpenedCalendar = args.popup.element as HTMLElement;
            this.customPopupMonthCell(this.currentOpenedCalendar);
        }
    }

    onNavigate(args: any) {
        if (this.currentOpenedCalendar && this.onlyAllowEndQuarterMonth) {
            this.customPopupMonthCell(this.currentOpenedCalendar);
        }
    }

    customPopupMonthCell(currentOpenPopup: HTMLElement) {
        let dataCells = currentOpenPopup.getElementsByClassName('e-day');
        for (let index = 0; index < dataCells.length; index++) {
            const monthData = (dataCells[index] as HTMLElement).innerText;
            if (monthData && _.isNaN(+monthData) && monthData !== 'Mar'
                && monthData !== 'Jun' && monthData !== 'Sep' && monthData !== 'Dec') {
                (dataCells[index] as HTMLElement).parentElement.classList.add('disabled-month');
            }
        }
    }
}
