import { Injectable } from '@angular/core';

export const DEFAULT_FILTER_SECTORS = {
    MEDICAL_OFFICE: ['Medical Office'],
    SENIOR_HOUSING: ['Senior Housing'],
    STUDENT_MULTI: ['Multifamily', 'Student Housing']
};

export const DEFAULT_FILTER_FUNDS = {
    OPPORTUNITY: ['KAREP I', 'KAREP II', 'KAREP III', 'KAREP IV', 'KAREP V', 'KAREP VI'],
    CORE: ['KACORE', 'KAMOB', 'KAMOB I', 'KAMOB II', 'KAMOB III', 'KAMOB IV', 'KAMOB V', 'KAMOB VI', 'KAMOB VII', 'KASH'],
    IMPACT: ['KAMF']
};

export const FILTER_GEO_TYPE = {
    GEOGRAPHY: 'GEOGRAPHY',
    STATE: 'STATE',
    DIVISION: 'DIVISIONS',
    METRO: 'METRO AREA'
};

@Injectable({
    providedIn: 'root',
})
export class MainFilterService {
    private _funds: any[] = [];
    private _allProperty: any[] = [];
    get funds() { return this._funds; }
    set funds(input: any[]) { this._funds = input; }

    get allProperty() { return this._allProperty; }
    set allProperty(input: any[]) { this._allProperty = input; }

    constructor() { }
}
